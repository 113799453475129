import { isPlatformServer, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { Subscription, SubscriptionLike } from 'rxjs';
import { ModalHistoryService } from './modal-history.service';

@Component({
  selector: 'pxw-modal-history',
  template: '',
  standalone: true,
})
export class ModalHistoryComponent implements OnInit, OnDestroy {
  @Input() name: string | undefined;
  @Input() title: string | undefined;
  @Output() popState = new EventEmitter();
  @Input() isBoundToRoute = false;

  private popStateSubscription$: SubscriptionLike | undefined;
  private routerSubscription$: Subscription;
  private active = false;
  private isLoadingModule = false;

  private platformId = inject(PLATFORM_ID);

  private location = inject(Location);
  private router = inject(Router);
  private modayHistoryService = inject(ModalHistoryService);

  constructor() {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get locationState(): any {
    return this.location.getState() || {};
  }

  ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    this.modayHistoryService.push(this);

    this.popStateSubscription$ = this.location.subscribe(popState => {
      if (this.modayHistoryService.isActiveInstance(this)) {
        this.modayHistoryService.pop();

        this.handlePopState(popState);
      }
    });

    this.routerSubscription$ = this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.isLoadingModule = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.isLoadingModule = false;
      }
    });

    /* If `isActive` is set and we haven't made an entry to the browser history state yet,
     * push our new entry onto the stack. From here on, when the browser back button is pressed,
     * the page won't change, and we can handle that event above to fire our internal functions. */
    if (!window.history.state || this.locationState.name !== this.name) {
      this.active = true;

      // If it is bound to route, then there is already a location.state to bind the instance to
      if (!this.isBoundToRoute) {
        /* Push the new entry to the state, as well as any existing state in the browser history. */
        this.location.go(this.router.routerState.snapshot.url, undefined, {
          ...this.locationState,
          name: this.name,
          title: this.title,
        });
      }
    }
  }

  ngOnDestroy() {
    this.handleBack();
  }

  /**
   * Ensures the browser history state stays up to date for when the browser back button
   * is pressed, and when the hook being called from inside a function component is fired.
   */
  handleBack() {
    // console.log(this.active, this.location.getState(), this.locationState.name, this.name, this.isLoadingModule);

    if (!this.active) {
      return;
    }
    this.active = false;

    this.popStateSubscription$?.unsubscribe();
    this.routerSubscription$?.unsubscribe();

    /* Force the browser to go back to remove the entry from the browser history state. */
    if (
      this.location.getState() &&
      this.locationState.name === this.name &&
      !this.isLoadingModule
    ) {
      this.location.back();
    }
    this.popState.emit();
  }

  /**
   * Handles the window event for `popstate` being fired.
   * If our entry exists in the browser history state, we can assume the back button has been
   * pressed. Otherwise, we can assume the forward button has been pressed.
   */
  private handlePopState(event: { state?: { name: string } }) {
    /* If the entry doesn't exist, assume it has just been popped from the state (i.e. back
     * button pressed). */
    if (!event.state || event.state?.name !== this.name) {
      this.handleBack();
    }
  }
}
